const garanDmak = 'https://d2dyoi7emzmazg.cloudfront.net/garanDmak.jpg';
const redDry = 'https://d2dyoi7emzmazg.cloudfront.net/redDry.jpg';
const reserveDry = 'https://d2dyoi7emzmazg.cloudfront.net/reserveDry.jpg';
const roseDry = 'https://d2dyoi7emzmazg.cloudfront.net/roseDry.jpg';
const tigraniSpecial = 'https://d2dyoi7emzmazg.cloudfront.net/tigraniSpecial.jpg';
const whiteDry = 'https://d2dyoi7emzmazg.cloudfront.net/whiteDry.jpg';
const bambak1 = 'https://d2dyoi7emzmazg.cloudfront.net/bambak1.jpg'
const bambak2 = 'https://d2dyoi7emzmazg.cloudfront.net/bambak2.jpg'
const bambak3 = 'https://d2dyoi7emzmazg.cloudfront.net/bambak3.jpg'
const bambak4 = 'https://d2dyoi7emzmazg.cloudfront.net/bambak4.jpg'

export const wines ={
  1: {
    img: garanDmak,
    title: 'SPECIAL SELECTION GARAN DMAK WHITE DRY',
    text: 'Floral, fruit delicate aroma with pear, green apple and orange, harmonious, fruit delicate taste with balanced acidity.',
    vol: '12.6',
  },
  2: {
    img: redDry,
    title: 'RED DRY',
    text: 'Full-bodied, berries and floral tones with pronounced aromas of black currant, cherry, roses and spices enriched with the full-bodied, fruity, balanced and velvet tannic taste. Delicate, long aftertaste.',
    vol: '13.4',
  },
  3: {
    img: reserveDry,
    title: 'RESERVE DRY',
    text: 'Full-bodied, berries and floral tones with pronounced aromas of spices, cherry, pomegranate and oak, with the harmonious taste of berries, oak and velvet tannins',
    vol: '13.1',
  },
  4: {
    img: roseDry,
    title: 'ROSE DRY',
    text: 'Full-bodied, with tones of tropical fruits barberry and strawberries, harmonious taste with tones of grapefruit, barberry and strawberries. Long aftertaste with sweet and harmonious notes ',
    vol: '14.9',
  },
  5: {
    img: tigraniSpecial,
    title: 'SPECIAL SELECTION TIGRANI RED DRY',
    text: 'Full-bodied, berries tones with pronounced aromas of blackberry, black currant and oak, with the harmonious taste of berries, oak and velvet tannins',
    vol: '14.5',
  },
  6: {
    img: whiteDry,
    title: 'WHITE DRY',
    text: 'Floral, fruit delicate aroma with pear, green apple and orange, harmonious, fruit delicate taste with balanced acidity.',
    vol: '14.0',
  },
  7: {
    img: bambak1,
    title: 'BAMBAK ROSE DRY',
    text: 'Full-bodied, with tones of tropical fruits barberry and strawberries, harmonious taste with tones of grapefruit, barberry and strawberries. Long aftertaste with sweet and harmonious notes.',
    vol: '14.9',
  },
  8: {
    img: bambak2,
    title: 'BAMBAK RED DRY',
    text: 'Full-bodied, berries and floral tones with pronounced aromas of black currant, cherry, roses and spices enriched with the full-bodied, fruity, balanced and velvet tannic taste. Delicate, long aftertaste.',
    vol: '13.4',
  },
  9: {
    img: bambak3,
    title: 'BAMBAK WHITE DRY',
    text: 'Floral, fruit delicate aroma with pear, green apple and orange, harmonious, fruit delicate taste with balanced acidity.',
    vol: '14.2',
  },
  10: {
    img: bambak4,
    title: 'BAMBAK Orange DRY',
    text: 'Floral, fruit delicate aroma with pear, green apple and orange, harmonious, fruit delicate taste with balanced acidity.',
    vol: '14.0',
  },
}
